<template>
  <div
    class="fixed bottom-0 left-0 z-20 h-16 w-full border-t border-gray-200 bg-white"
  >
    <div
      class="mx-auto grid h-full max-w-lg font-medium text-gray-700"
      :class="[isVerifiedGigWorker ? 'grid-cols-4' : 'grid-cols-3']"
    >
      <NuxtLink
        to="/"
        class="group inline-flex flex-col items-center justify-center px-5"
      >
        <HomeIcon class="mb-1 h-6 w-6 group-hover:text-indigo-500" />
        <span class="text-sm">Homes</span>
      </NuxtLink>
      <NuxtLink
        to="/workorders"
        class="group inline-flex flex-col items-center justify-center px-5"
      >
        <TicketIcon class="mb-1 h-6 w-6 group-hover:text-indigo-500" />
        <span class="text-sm">Orders</span>
      </NuxtLink>
      <NuxtLink
        v-if="isVerifiedGigWorker"
        to="/gigs"
        class="group inline-flex flex-col items-center justify-center fill-gray-700 px-5"
      >
        <GigWorkerIcon class="mb-1 h-6 w-6 group-hover:text-indigo-500" />
        <span class="text-sm">Gigs</span>
      </NuxtLink>
      <NuxtLink
        to="/feedback"
        class="group inline-flex flex-col items-center justify-center px-5"
      >
        <ChatBubbleLeftEllipsisIcon
          class="mb-1 h-6 w-6 group-hover:text-indigo-500"
        />
        <span class="text-sm">Feedback</span>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { HomeIcon } from "@heroicons/vue/20/solid";
import {
  ChatBubbleLeftEllipsisIcon,
  TicketIcon,
} from "@heroicons/vue/24/outline";
import { type IUser } from "hk-data/types";
import GigWorkerIcon from "~/assets/icons/gig-worker.svg";

const { data } = useAuth();

const user: IUser = data?.value?.user;

const isVerifiedGigWorker = user?.isVerifiedGigWorker || false;
</script>

<style scoped>
.router-link-active {
  @apply fill-indigo-500 text-indigo-500;
}
</style>
